import { extendTheme } from '@chakra-ui/react';

import styles from './styles';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};
const colors = {
  blue: {
    50: '#F3F5FF',
    100: '#E8EBFB',
    200: '#BAC2F2',
    300: '#A3AEED',
    400: '#8C9AE9',
    500: '#2C59A6',
  },
  primary: {
    100: '#D2EBF3',
    200: '#BBE2ED',
    300: '#A4D8E7',
    400: '#60BBD4',
    500: '#49B1CE',
  },
  secondary: {
    100: '#F0E8F9',
    200: '#E1D2F3',
    300: '#C3A4E7',
    400: '#A577DA',
    500: '#764CD9',
  },
};

const breakpoints = {
  base: '0em',
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '84em',
  '2xl': '96em',
  '3xl': '120em',
};


const theme = extendTheme({
  styles,
  colors,
  config,
  breakpoints,
  components: {
    Tabs: {
      variants: {
        enclosed: {
          tablist: {
            borderBottom: '0px', // Define el grosor del borde
          },
          tab: {
            _selected: {
              borderColor: 'gray.300',
              borderWidth: '1px',
              backgroundColor: 'white',
            },
          },
        },
      },
    },
  },
});

export default theme;
