/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useRef, React } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box } from '@chakra-ui/react';
import { flexRender } from '@tanstack/react-table';

import { updateAprovedBulk, updateDeletedBulk } from '../actions/bulkActions';
import { cleanContactsDetails, cleanListContacts, listClientsDropdown } from '../actions/clientActions';
import { createNewTrip } from '../actions/createTripAction';
import { listDropdownMunicipality, listMunicipalitiesDropdown } from '../actions/municipalityActions';
import {
  approveTripRequest,
  cleanlistTripRequestForwardMessages,
  cleanlistTripRequestMessages,
  deleteTripRequest,
  forwardForwardBidRequestMessage,
  getTripRequestDetails,
  listTripRequests,
  listTripRequestsDownload,
  rejectTripRequest,
  unreadTripRequest,
} from '../actions/tripRequestActions';
import { listUnitTypesDropdown } from '../actions/unitTypeActions';
// import DownloadCSVButton from "../components/DownloadCSVButton";
import httpClientFormData from '../actions/utils/axiosInstanceFormData';
import AssignSupplier from '../components/AssignSupplier';
import ForwardMessage from '../components/ForwardMessage';
import SortIcon from '../components/icons/SortIcon';
import ScrollableChat from '../components/ScrollableChat';
import SureModal from '../components/SureModal';
import AssignSupplierModal from '../components/tripRequest/AssignRequest';
import CarouselModal from '../components/tripRequest/CarouselModal';
import GridTripsRequest from '../components/tripRequest/GridTripsRequest';
import RejectedModal from '../components/tripRequest/RejectedModal';
import TableActionGloblal from '../components/tripRequest/TableActionGloblal';
import TableTripRequest from '../components/tripRequest/TableTripRequest';
import UploadFileModal from '../components/tripRequest/UploadFileModal';
import TripRequestPaginator from '../components/TripRequestPaginator';
import { formattedData } from '../components/TripTableDateSelect';
import CreateTripModal from '../components/utils/modals/CreateTripModal';
import DetailsMessagesModal from '../components/utils/modals/DetailsMessagesModal';
import TableSkeleton from '../components/utils/skeletons.jsx/TableSkeleton';
import { filterByStatusTrips, statusesMap, handleRecent } from '../components/utils/TabsFilters';
import moment from 'moment';
import { debounce } from 'lodash'; 
import { v4 as uuidv4 } from 'uuid';

const TripTableTemplate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Use useHistory
  const tripRequestList = useSelector((state) => state.tripRequestList);
  const { error, loading, tripRequests = [], pagination, filters } = tripRequestList;
  const getStatuses = tripRequests?.map((item) => ({
    ...item,
    is_recent: handleRecent(item?.created_at),
    status: {
      // Mapping boolean statuses to keys
      '1 is_approved': item.is_approved,
      '2 suppliers_have_been_contacted': item.suppliers_have_been_contacted,
      '3 is_approved_by_traffic': item.is_approved_by_traffic,
      '4 assigned_transporter': !!item.assigned_transporter,
      '5 is_complete': item.is_complete,
      '6 is_rejected': item.is_rejected,
    },
  }));
  const transformedData = getStatuses?.map((item) => {
    const statusKeys = Object.keys(item.status);
    let lastTrueStatus = null;
    // Finding the last true status for know what current status is the trip
    for (let i = statusKeys.length - 1; i >= 0; i--) {
      const key = statusKeys[i];
      if (item.status[key] === true) {
        lastTrueStatus = key.split(' ')[1]; // Extracting the status key without the number prefix
        break;
      }
    }

    return {
      ...item,
      status: lastTrueStatus, // Updating the status field with the last true status found
    };
  });

  const [sortConfig, setSortConfig] = useState({
    key: 'requested_at',
    direction: 'desc',
  });
  const [data, setData] = useState([]);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [tablePage, setTablePage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [currentTripId, setCurrentTripId] = useState();
  const [isChatOpen, setChatOpen] = useState(false);
  const [isForwardMessageOpen, setForwardMessageOpen] = useState(false);
  const [isAssignSupplierOpen, setAssignSupplierOpen] = useState(false);
  const [isAssignSupplierTripOpen, setAssignSupplierTripOpen] = useState(false);
  const [isTripRequestModalOpen, setTripRequestModalOpen] = useState(false);
  const [isConversationsModalOpen, setConversationsModalOpen] = useState(false);
  const [conversationsIds, setConversationsIds] = useState([]);
  const [tripData, setTripData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(''); // default filter
  const [supplierSelected, setSupplierSelected] = useState(null);
  const [selectAll, setSelectAll] = useState(false);

  const [showBulkModal, setShowBulkModal] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [elementShow, setElementShow] = useState(40);
  const [withMaxScreen, setWithMaxScreen] = useState(1375);
  const userDetailsData = useSelector((state) => state.userDetails);

  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(null);
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [isABidSet, setIsABidSet] = useState(false);
  const [socket, setSocket] = useState(null);





  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket('wss://a9bwkzfk83.execute-api.us-east-1.amazonaws.com/dev/');
    //socket events
    socket.onopen = () => {
      console.log('WebSocket Connected');
    };
    //listen to messages and refresh if a message is received
    socket.onmessage = (event) => {
      console.log('MESSAGE');
      // refreshTripRequests('socket');
      // fetchTripRequests('socket');
      fetchTripRequestsRef.current('socket');  // Use the latest function reference

    };
    //handle errors
    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
    };

    socket.onclose = () => {
      console.log('WebSocket Connection Closed');
    };

    setSocket(socket);

    // Cleanup on component unmount
    return () => {
      socket.close();
    };
  }, []);
  //handles the file upload process
  const handleUploadFile = async (event) => {
    event.preventDefault();
    const file = fileUploaded;
    if (!file) {
      toast.error('No hay archivo seleccionado');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    setIsUploadLoading(true);
    try {
      await httpClientFormData.post('api/v1/trip-requests/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Archivo subido exitosamente');
      setIsUploadLoading(false);
      setFileUploaded('');
      setIsOpenUploadModal(false);
      event.target.value = null;
      // refreshTripRequests('handleUploadFile');
      fetchTripRequests('handleUploadFile');
    } catch (error) {
      setIsUploadLoading(false);
      toast.error('Error al subir archivo🥲');
      setFileUploaded('');
    }
  };
  //? both function should be in another component
  //  upload process but also sends the file to the suppliers
  const handleUploadAndSendFile = async (event) => {
    event.preventDefault();
    const file = fileUploaded;
    if (!file) {
      toast.error('No hay archivo seleccionado');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('send_to_suppliers', 'true');
    setIsUploadLoading(true);
    try {
      await httpClientFormData.post('api/v1/trip-requests/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Archivo subido exitosamente');
      setIsUploadLoading(false);
      setFileUploaded('');
      setIsOpenUploadModal(false);
      event.target.value = null;
      // refreshTripRequests('handleUploadAndSendFile');
      fetchTripRequests('handleUploadAndSendFile');
    } catch (error) {
      setIsUploadLoading(false);
      toast.error('Error al subir archivo🥲');
      setFileUploaded('');
    }
  };
  // toggles the visibility of the upload modal
  const handleUploadModal = () => {
    setIsOpenUploadModal(!isOpenUploadModal);
  };
  //sets up various states for displaying the assign modal.
  const handleOpenAssignModal = (params) => {
    setIsRejected(params.is_rejected);
    setCurrentTripId(params.id);
    setConversationsIds(params.forwarded_supplier_chats);
    setTripData(params);

    setAssignSupplierOpen(true);
  };
  // closes the upload modal and resets related states
  const handleCloseAssignModal = () => {
    setAssignSupplierOpen(false);
    setIsRejected(false);
    setCurrentTripId(null);
    setConversationsIds([]);
    setTripData([]);
    dispatch(cleanlistTripRequestForwardMessages());
  };

  const handleCloseUploadModal = () => {
    setIsOpenUploadModal(false);
    setIsUploadLoading(false);
    setFileUploaded(null);
  };
  const { errorUserDetails, loading: loadingUserDetails, userDetails } = userDetailsData;

  const tripRequestDetails = useSelector((state) => state.tripRequestDetails);
  const { loading: loadingTripRequestDetails } = tripRequestDetails;

  const { loading: loadingAprovedBulk } = useSelector((state) => state.aprovedBulk);
  const { loading: loadingDeletedBulk } = useSelector((state) => state.deleteBulk);

  const { municipalities } = useSelector((state) => state.municipalityDropdownList);
  const { clients } = useSelector((state) => state.clientDropdownList);
  const { unitTypes } = useSelector((state) => state.unitTypeDropdownList);

  // if the user is a supplier coordinator, add the supplier column
  const hasSupplierCoordination = userDetails?.roles.some((role) => role.id_verbose === 'supplier_coordination');

  // if the user is a supplier coordinator, add the supplier column
  const hasClientCoordination = userDetails?.roles.some((role) => role.id_verbose === 'client_coordination');

  // Fetch filter options when the component mounts
  useEffect(() => {
    if (error) return;

    dispatch(listMunicipalitiesDropdown());
  }, [dispatch]);

  //only for filters
  useEffect(() => {
    if (error) return;

    dispatch(listDropdownMunicipality());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;

    dispatch(listClientsDropdown());
  }, [dispatch]);

  useEffect(() => {
    if (error) return;
    dispatch(listUnitTypesDropdown());
  }, [dispatch]);




  // Debounce the filter updates
  const debouncedSetSelectedFilters = useCallback(
    debounce((newOptions) => {
      console.log('Updating selected filters:', newOptions);
      setSelectedFilters(newOptions);
    }, 500),
    []
  );


  // Update useEffect for selectedOptions
  useEffect(() => {
    if (error) return;

    // Build selected filters from options
    const buildSelectedFilters = Object.entries(selectedOptions)
      .filter(([_, value]) => !!value)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    // Debounce the filter updates
    debouncedSetSelectedFilters(buildSelectedFilters);
    setTablePage(1); // Reset to first page when filters change

  }, [selectedOptions, selectedOption, error, debouncedSetSelectedFilters]);
  // update tripRequests every TIMER_MS
  const TIMER_MS = 10000;






  // Create a debounced version of fetchTripRequests
  const debouncedFetchTripRequests = useCallback(
    debounce((params) => {
      const requestId = uuidv4().slice(0, 8);
      console.log(`[${requestId}] Starting fetch trip requests...`, {
        page: params.page,
        pageSize: params.pageSize,
        filters: params.filters,
        status: params.status,
        sorting: `${params.sortConfig.key}-${params.sortConfig.direction}`,
      });

      dispatch(
        listTripRequests({
          order_by: params.sortConfig.key,
          ordering: params.sortConfig.direction,
          page: params.page,
          page_size: params.pageSize,
          ...statusesMap[params.status],
          ...params.filters,
        })
      ).then(() => {
        console.log(`[${requestId}] Completed fetch trip requests`);
      }).catch((error) => {
        console.error(`[${requestId}] Failed to fetch trip requests:`, error);
      });
    }, 200),
    []
  );


  const debouncedRefreshTripRequests = useCallback(
    debounce(() => {
      const requestId = uuidv4().slice(0, 8);
      console.log(`[${requestId}] Starting refresh trip requests...`, {
        page: tablePage,
        pageSize: elementShow,
        filters: selectedFilters,
        status: selectedStatus,
        sorting: `${sortConfig.key}-${sortConfig.direction}`,
      });
      
      dispatch(
        listTripRequests({
          order_by: sortConfig.key,
          ordering: sortConfig.direction,
          page: tablePage,
          page_size: elementShow,
          ...selectedFilters,
          ...statusesMap[selectedStatus],
          refresh: true,
        })
      ).then(() => {
        console.log(`[${requestId}] Completed refresh trip requests`);
      }).catch((error) => {
        console.error(`[${requestId}] Failed to refresh trip requests:`, error);
      });
    }, 500),
    [sortConfig, tablePage, elementShow, selectedFilters, selectedStatus, dispatch]
  );

  // You can also wrap all calls to debouncedRefreshTripRequests with a caller identifier
  const refreshTripRequests = useCallback((caller = 'unknown') => {
    console.log(`Manual refresh triggered from: ${caller}`);
    debouncedRefreshTripRequests();
  }, [debouncedRefreshTripRequests]);




  useEffect(() => {
    // Skip if any blocking conditions
    if (
      error ||
      errorUserDetails ||
      loadingUserDetails ||
      isConversationsModalOpen ||
      isChatOpen ||
      isTripRequestModalOpen ||
      isForwardMessageOpen ||
      isRejectModalOpen ||
      isOpenUploadModal ||
      loadingDeletedBulk ||
      loadingAprovedBulk
    ) {
      return;
    }
  
    const requestId = uuidv4().slice(0, 8);
    console.log(`[${requestId}] Triggering fetch from useEffect`, {
      trigger: 'dependency change',
      filters: selectedFilters,
      page: tablePage,
      sort: sortConfig,
    });
    
    // Pass all current values as an object
    debouncedFetchTripRequests({
      page: tablePage,
      pageSize: elementShow,
      filters: selectedFilters,
      status: selectedStatus,
      sortConfig: sortConfig,
    });
    
    return () => {
      debouncedFetchTripRequests.cancel();
    };
  }, [
    tablePage,
    selectedFilters, // Now this only changes once the debounced filter update completes
    sortConfig,
    selectedStatus,
    isConversationsModalOpen,
    isChatOpen,
    isTripRequestModalOpen,
    isForwardMessageOpen,
    isRejectModalOpen,
    isOpenUploadModal,
    error,
    errorUserDetails,
    loadingDeletedBulk,
    loadingAprovedBulk,
    debouncedFetchTripRequests,
  ]);
  





  //This is a  delicate useEffect
  useEffect(() => {
    if (error || loadingUserDetails) return;
    // Update the data when tripRequests change
    setData(transformedData);
  }, [error, tripRequests, loadingUserDetails]);
  //set page to 1 when error
  useEffect(() => {
    if (error === 'Invalid page.') {
      setTablePage(1);
    }
  }, [error]);

  const openChatModal = (id) => {
    setCurrentTripId(id);
    setChatOpen(true);
  };
  //modals funcitons
  const closeChatModal = () => {
    setCurrentTripId(null);
    setChatOpen(false);
  };

  /**
   *
   * @param  {number}: id
   * @param  {boolean}: isFile
   */
  const openForwardMessageModal = (id, isFile) => {
    setIsABidSet(isFile);
    setCurrentTripId(id);
    setForwardMessageOpen(true);
  };

  const closeForwardMessageModal = () => {
    if (!openForwardMessageModal) {
      setCurrentTripId(null);
    }
    // setCurrentTripId(null);
    setForwardMessageOpen(false);
    setIsABidSet(false);
    dispatch(cleanlistTripRequestForwardMessages());
  };
  /**
   *
   * @param  {number}: id
   * @param  {boolean}: isFile
   */
  const openAssignSupplierTrafficModal = (id, supplier) => {
    setSupplierSelected(supplier);
    setCurrentTripId(id);
    setAssignSupplierTripOpen(true);
  };
  const closeAssignSupplierTrafficModal = () => {
    setSupplierSelected(null);
    setCurrentTripId(null);
    setAssignSupplierTripOpen(false);
  };
  /**
   *
   * @param  {number}: id
   * @param  {boolean}: isFile
   */
  const openTripRequestModal = async (id, isFile) => {
    await dispatch(cleanlistTripRequestMessages());
    setIsABidSet(!!isFile);
    setCurrentTripId(id);
    setTripRequestModalOpen(true);
  };

  const closeTripRequestModal = async () => {
    setCurrentTripId(null);
    setTripRequestModalOpen(false);
    setIsABidSet(false);
    await dispatch(cleanlistTripRequestMessages());
  };
  // open and close carousel conversations MODAL
  const handleConversationsModal = () => {
    setConversationsModalOpen(!isConversationsModalOpen);
    if (!isAssignSupplierOpen) {
      setConversationsIds([]);
      setCurrentTripId(null);
    }
  };
  /**
   *
   * @param {object[]} forwardedSupplierChats
   * @param {number} id
   */
  const handleOpenConversationsModal = (forwardedSupplierChats, id) => {
    if (forwardedSupplierChats.length > 0) {
      setCurrentTripId(id);
      setConversationsIds(forwardedSupplierChats);
    }
    setConversationsModalOpen(!isConversationsModalOpen);
  };



  // You can also wrap all calls to debouncedRefreshTripRequests with a caller identifier
  const fetchTripRequests = useCallback((caller = 'unknown') => {
    console.log(`Manual fetch triggered from: ${caller}`);
    // Pass all current values as an object
    debouncedFetchTripRequests({
      page: tablePage,
      pageSize: elementShow,
      filters: selectedFilters,
      status: selectedStatus,
      sortConfig: sortConfig,
    });
  }, [debouncedFetchTripRequests, tablePage, elementShow, selectedFilters, selectedStatus, sortConfig]);


  const fetchTripRequestsRef = useRef(fetchTripRequests);

  useEffect(() => {
    fetchTripRequestsRef.current = fetchTripRequests;
  }, [fetchTripRequests]);



  // Identical function to fetch trip requests with but calling listTripRequestsDownload action
  // should be refactored to avoid code duplication
  const fetchTripRequestsDownload = () => {
    // Call the listTripRequests action with sorting and filter parameters
    dispatch(
      listTripRequestsDownload({
        order_by: sortConfig.key,
        ordering: sortConfig.direction,
        page: tablePage,
        page_size: elementShow,
        ...statusesMap[selectedStatus],
        // Include selected filters
        ...selectedFilters,
      })
    );
  };

  const handleApprove = async (id, value) => {
    const isApproved = value;
    await dispatch(approveTripRequest(id, isApproved));
    // refreshTripRequests('handleApprove');
    fetchTripRequests('handleApprove');
  };

  const handleDownloadCSV = () => {
    // Call the listTripRequests action with sorting and filter parameters
    fetchTripRequestsDownload();
  };
  //table actions
  const fetchNextPage = () => {
    setTablePage(pagination.currentPage + 1);
    // fetchTripRequests(sortConfig);
  };
  const fetchPreviousPage = () => {
    setTablePage(pagination.currentPage - 1);
    // fetchTripRequests(sortConfig);
  };
  const handleColumnSort = (column) => {
    // Update the sortConfig state
    const newDirection = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: column.id, direction: newDirection });
  };




  const handleSelectChange = useCallback((columnId, selectedOption, endDate) => {
    const columFilterDictionary = {
      is_attended: 'is_attended',
      requested_at: 'requested_at',
      loading_date: 'loading_date',
      unloading_date: 'unloading_date',
    };
  
    setSelectedOption(selectedOption);
  
    // Batch the filter updates
    setSelectedOptions((prevSelectedOptions) => {
      let newOptions = { ...prevSelectedOptions };
  
      if (selectedOption === null) {
        // Remove the filter
        delete newOptions[columFilterDictionary[columnId]];
      } else {
        if (columnId === 'requested_at') {
          newOptions = {
            ...newOptions,
            requested_at_after: selectedOption ? formattedData(selectedOption) : selectedOption,
            requested_at_before: endDate ? formattedData(endDate) : endDate,
          };
        } else if (columnId === 'unloading_date') {
          newOptions = {
            ...newOptions,
            unloading_date_after: selectedOption ? formattedData(selectedOption) : selectedOption,
            unloading_date_before: endDate ? formattedData(endDate) : endDate,
          };
        } else {
          newOptions[columFilterDictionary[columnId]] = selectedOption;
        }
      }
      return newOptions;
    });
  }, []);



  // Location search handler with debounce
  const handleSearchLocation = useCallback((values) => {
    const columFilterDictionary = {
      origin: 'origin',
      destination: 'destination',
      client_id: 'client_id',
      unit_type: 'unit_type',
    };
  
    setSelectedOptions((prevSelectedOptions) => {
      const newOptions = { ...prevSelectedOptions };
      Object.entries(values).forEach(([columnId, value]) => {
        newOptions[columFilterDictionary[columnId]] = value;
      });
      return newOptions;
    });
  }, []);



  // delete a trip request
  const [showDeleteModal, setShowModal] = useState({
    id: null,
    show: false,
  });
  const deleteHandler = () => {
    dispatch(deleteTripRequest(showDeleteModal.id)).then(() => {
      // refreshTripRequests('deleteHandler');
      fetchTripRequests('deleteHandler');
      setShowModal({ id: null, show: false });
    });
  };

  const [readStatus, setReadStatus] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);
  // Initialize the readStatus state based on the initial data
  useEffect(() => {
    if (tripRequests) {
      const initialReadStatus = {};
      tripRequests.forEach((request) => {
        initialReadStatus[request.id] = request.seen_by_user;
      });
      setReadStatus(initialReadStatus);
    }
  }, [tripRequests]);
  /**
   * @param {*} id
   */
  const handleReadStatusToggle = (id) => {
    // Retrieve the current read status of the item with the given id
    const currentStatus = readStatus[id];
    // Disable the button for the current item to prevent multiple clicks
    setDisabledButtons(() => ({
      [id]: true,
    }));
    // Toggle the read status for the item with the given id
    setReadStatus((prevReadStatus) => ({
      ...prevReadStatus,
      [id]: !prevReadStatus[id],
    }));
    // If the current status is true (read), dispatch an action to mark it as unread
    if (currentStatus) {
      dispatch(unreadTripRequest(id)).then(() => {
        // Re-enable the button after fetching the details
        setDisabledButtons({});
      });
    } else {
      // If the current status is false (unread), dispatch an action to fetch the trip request details
      dispatch(getTripRequestDetails(id)).then(() => {
        // Re-enable the button after fetching the details
        setDisabledButtons({});
      });
    }
  };
  // handleSelectAll toggles the selectAll state and updates the checkedItems state based on the new selectAll state.
  // If selectAll is false (meaning not all items are currently selected), it:
  // 1. Sets selectAll to true.
  // 2. Maps through the data array to create a new array of objects with each item's id and supplierId (from item.preassigned_transporter).
  // 3. Sets this new array as the value of checkedItems, effectively selecting all items.
  // If selectAll is true (meaning all items are currently selected), it:
  // 1. Sets selectAll to false.
  // 2. Sets checkedItems to an empty array, effectively deselecting all items.
  const handleSelectAll = () => {
    setSelectAll(!selectAll); // Toggle the current state of selectAll.
    if (!selectAll) {
      // If selectAll is currently false, select all items.
      const allItems = data.map((item) => ({ id: item.id, supplierId: item.preassigned_transporter }));
      setCheckedItems(allItems); // Update checkedItems with all items selected.
    } else {
      setCheckedItems([]);
    }
  };
  const [lastChecked, setLastChecked] = useState(null);
  // handleCheckboxChange manages the selection and deselection of items in a list with the option to select multiple items using the Shift key.
  // - options: An object containing the id, index, and supplierId of the item associated with the checkbox change event.
  // - event: The event object from the checkbox change event.
  const handleCheckboxChange = (options, event) => {
    const { id, index, supplierId } = options;
    const shiftKeyPressed = event.nativeEvent.shiftKey; // Check if the Shift key was pressed during the event.
    const newItem = { id, supplierId }; // Create an object for the current item.

    if (shiftKeyPressed && lastChecked !== null) {
      // If Shift key is pressed and there's a last checked item.
      const firstIndex = Math.min(index, lastChecked.index); // Determine the start of the range to select/deselect.
      const lastIndex = Math.max(index, lastChecked.index); // Determine the end of the range to select/deselect.

      const newCheckedItems = new Set(checkedItems.map((item) => JSON.stringify(item))); // Create a Set of currently checked items as JSON strings.
      const deleteCheckouts = newCheckedItems.has(
        JSON.stringify({ id: data[index].id, supplierId: data[index].preassigned_transporter })
      ); // Determine if we're selecting or deselecting.
      for (let i = firstIndex; i <= lastIndex; i++) {
        // Iterate over the range.
        const currentItem = { id: data[i].id, supplierId: data[i].preassigned_transporter };
        if (deleteCheckouts) {
          // If we're deselecting items.
          newCheckedItems.delete(JSON.stringify(currentItem)); // Remove the item from the set.
          continue;
        }
        newCheckedItems.add(JSON.stringify(currentItem)); // Add the item to the set.
      }

      setCheckedItems(Array.from(newCheckedItems).map((item) => JSON.parse(item)));
    } else {
      setCheckedItems((prevCheckedItems = []) => {
        // Use a functional update to ensure we have the latest state.
        const newCheckedItems = new Set(prevCheckedItems.map((item) => JSON.stringify(item)));
        const itemString = JSON.stringify(newItem);

        if (newCheckedItems.has(itemString)) {
          newCheckedItems.delete(itemString);
        } else {
          newCheckedItems.add(itemString);
        }

        return Array.from(newCheckedItems).map((item) => JSON.parse(item)); // Update the checkedItems state with the new set of items.
      });
    }

    setLastChecked({ index, id, supplierId }); // Update the lastChecked state with the current item's details.
  };
  //aprove select items in handleCheckboxChange
  const aprovedBulk = () => {
    const data = checkedItems || selectAll;
    const dataIds = data.map((item) => item.id);
    dispatch(updateAprovedBulk(dataIds))
      .then(() => {
        setSelectAll(false);
        setCheckedItems([]);
        toast.success('Solicitudes aprovadas exitosamente');
      })
      .catch((error) => {
        console.error('error in aproved bulk: ', error);
      });
  };
  //forward select items of handleCheckboxChange
  const forwardBulk = async () => {
    //determines the data to be forwarded based on whether items are individually checked (`checkedItems`) or all items are selected (`selectAll`).
    const data = checkedItems || selectAll;
    const transformData = data.map((item) => ({
      //data is then transformed into the required format
      send_to: [item?.supplierId],
      trip_id: item?.id,
    }));
    try {
      await dispatch(forwardForwardBidRequestMessage(transformData));
      setSelectAll(false);
      setCheckedItems([]);
      toast.success('Enviadas exitosamente');
    } catch {
      console.error('error in aproved bulk: ', error);
    }
  };
  //deletedBulk select items of handleCheckboxChange
  const deletedBulk = () => {
    const data = checkedItems || selectAll;
    const dataIds = data.map((item) => item.id);

    dispatch(updateDeletedBulk(dataIds))
      .then(() => {
        setSelectAll(false);
        setCheckedItems([]);
        toast.success('Solicitudes eliminadas');
        setShowBulkModal(false);
      })
      .catch((error) => {
        console.error('error in deleted bulk: ', error);
        setShowBulkModal(false);
      });
  };
  //  cancel a trip request modals - open and close and submit (cand reject or activate)
  const handleRejectedModal = (id, params) => {
    setIsRejected(params);
    setCurrentTripId(id);
    setIsRejectModalOpen(true);
  };

  const handleCloseRejectModal = () => {
    setIsRejectModalOpen(false);
    handleCloseAssignModal();
    setCurrentTripId(null);
    setIsRejected(false);
  };
  // onSubmitRejectTrip is an asynchronous function that handles the submission of a trip rejection or reactivation.
  const onSubmitRejectTrip = async (values) => {
    const rejected = isRejected;
    const reason = values.reason === 'Otro' ? values.reasonOther : values.reason;
    const data = {
      is_rejected: rejected ? false : true,
      rejection_reason: reason || '',
    };
    dispatch(rejectTripRequest(currentTripId, data))
      .then(() => {
        rejected ? toast.success('Solicitud activada exitosamente') : toast.success('Solicitud rechazada exitosamente');

        setIsRejectModalOpen(false);
        setCurrentTripId(null);
        setIsRejected(false);
      })
      .catch((error) => {
        setIsRejected(false);
        console.error('error in rejected bulk: ', error);
        toast.error('Error en la solicitud');
      });
  };
  const [openCreateTrip, setOpenCreateTrip] = useState(false);
  const handleCreateTrip = () => {
    setOpenCreateTrip(!openCreateTrip);
  };
  // asynchronous function that prepares and dispatches data for creating a new trip:
  const handleCreateNewTrip = async (data) => {
    const params = {
      ...data,
      channel: data?.channel ?? '',
      destination_location: data?.destination_location ?? '',
      origin_location: data?.origin_location ?? '',
      folio_number: data?.folio_number ?? '',
      shipment_number: data?.shipment_number ?? '',
      unit_type: data?.unit_type ?? '',
      loading_date: data.loading_date ? formattedData(data.loading_date) : '',
      unloading_date: data.unloading_date ? formattedData(data.unloading_date) : '',
      loading_time: data.loading_time ? moment(data.loading_time).utcOffset(-6, true).format('HH:mm') : '',
      unloading_time: data.loading_time ? moment(data.unloading_time).utcOffset(-6, true).format('HH:mm') : '',
      message: data?.message ?? '',
    };
    // filter out empty values from the params object
    const filteredParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {});
    try {
      await dispatch(createNewTrip(filteredParams));
      setOpenCreateTrip(false);
      dispatch(cleanContactsDetails());
      dispatch(cleanListContacts());
      // refreshTripRequests('handleCreateNewTrip');
      fetchTripRequests('handleCreateNewTrip');
    } catch (error) {
      console.error('Error creating trip:', error);
    }
  };

  if (loadingUserDetails) return <TableSkeleton />;
  return (
    <Box position="relative" p={0} m={0} w={{ xl: withMaxScreen }}>
      <TableActionGloblal
        hasSupplierCoordination={hasSupplierCoordination}
        withMaxScreen={withMaxScreen}
        handleDownloadCSV={handleDownloadCSV}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        filterByStatusTrips={filterByStatusTrips}
        handleSelectChange={handleSelectChange}
        filters={filters}
        selectAll={selectAll}
        checkedItems={checkedItems}
        handleSelectAll={handleSelectAll}
        loadingDeletedBulk={loadingDeletedBulk}
        loadingAprovedBulk={loadingAprovedBulk}
        setShowBulkModal={setShowBulkModal}
        aprovedBulk={aprovedBulk}
        setSelectAll={setSelectAll}
        setLastChecked={setLastChecked}
        setCheckedItems={setCheckedItems}
        handleUploadModal={handleUploadModal}
        forwardBulk={forwardBulk}
        hasClientCoordination={hasClientCoordination}
        handleCreateTrip={handleCreateTrip}
        selectedOption={selectedOption}
        handleSearchLocation={handleSearchLocation}
        data={data}
      />
      <TableTripRequest
        handleRejectedModal={handleRejectedModal}
        checkedItems={checkedItems}
        handleCheckboxChange={handleCheckboxChange}
        unitTypes={unitTypes}
        municipalities={municipalities}
        clients={clients}
        filters={filters}
        handleColumnSort={handleColumnSort}
        SortIcon={SortIcon}
        handleSelectChange={handleSelectChange}
        openChatModal={openChatModal}
        openTripRequestModal={openTripRequestModal}
        flexRender={flexRender}
        data={data}
        loading={loading}
        hasSupplierCoordination={hasSupplierCoordination}
        hasClientCoordination={hasClientCoordination}
        handleApprove={handleApprove}
        handleOpenConversationsModal={handleOpenConversationsModal}
        openForwardMessageModal={openForwardMessageModal}
        setShowModal={setShowModal}
        readStatus={readStatus}
        disabledButtons={disabledButtons}
        handleReadStatusToggle={handleReadStatusToggle}
        loadingTripRequestDetails={loadingTripRequestDetails}
        setData={setData}
        columnFilters={columnFilters}
        elementShow={elementShow}
        withMaxScreen={withMaxScreen}
        setWithMaxScreen={setWithMaxScreen}
        selectAll={selectAll}
        handleSelectAll={handleSelectAll}
        openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
        handleAssignModal={handleOpenAssignModal}
        refreshTripRequests={refreshTripRequests}
      />
      <GridTripsRequest
        handleRejectedModal={handleRejectedModal}
        data={data}
        openChatModal={openChatModal}
        hasSupplierCoordination={hasSupplierCoordination}
        handleOpenConversationsModal={handleOpenConversationsModal}
        openForwardMessageModal={openForwardMessageModal}
        setShowModal={setShowModal}
        handleReadStatusToggle={handleReadStatusToggle}
        readStatus={readStatus}
        disabledButtons={disabledButtons}
        openTripRequestModal={openTripRequestModal}
        loading={loading}
        municipalities={municipalities}
        clients={clients}
        handleSelectChange={handleSelectChange}
        unitTypes={unitTypes}
        filters={filters}
        checkedItems={checkedItems}
        handleCheckboxChange={handleCheckboxChange}
        handleAssignModal={handleOpenAssignModal}
        openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
        hasClientCoordination={hasClientCoordination}
        refreshTripRequests={fetchTripRequests}
      />
      {!loading && data.length > 0 && (
        <TripRequestPaginator
          pagination={pagination}
          fetchNextPage={fetchNextPage}
          fetchPreviousPage={fetchPreviousPage}
          elementShow={elementShow}
          setElementShow={setElementShow}
          withMaxScreen={withMaxScreen}
          setTablePage={setTablePage}
        />
      )}
      {/* conversations modal */}
      <CarouselModal
        onClose={handleConversationsModal}
        isOpen={isConversationsModalOpen}
        conversations={conversationsIds}
        currentTripId={currentTripId}
        openForwardMessageModal={openForwardMessageModal}
      />
      {/* message chat */}
      <ScrollableChat id={currentTripId} isOpen={isChatOpen} onClose={closeChatModal} />
      <ForwardMessage
        id={currentTripId}
        isOpen={isForwardMessageOpen}
        onClose={closeForwardMessageModal}
        numberId={isABidSet ? 0 : 1}
      />
      <AssignSupplier
        id={currentTripId}
        isOpen={isAssignSupplierTripOpen}
        onClose={closeAssignSupplierTrafficModal}
        supplierSelected={supplierSelected}
        postUpdateCallback={refreshTripRequests}
      />
      {/* Detail and message, can edit trip request*/}
      <DetailsMessagesModal
        currentTripId={currentTripId}
        isOpen={isTripRequestModalOpen}
        onClose={closeTripRequestModal}
        refreshTripRequests={refreshTripRequests}
        isABidSet={isABidSet}
      />

      <SureModal show={showDeleteModal.show} onHide={() => setShowModal(false)} onConfirm={deleteHandler} />
      <RejectedModal
        show={isRejectModalOpen}
        onHide={handleCloseRejectModal}
        onSubmit={onSubmitRejectTrip}
        isRejected={isRejected}
        isLoading={loadingTripRequestDetails}
      />
      <SureModal
        show={showBulkModal}
        onHide={() => setShowBulkModal(false)}
        onConfirm={deletedBulk}
        title="¿Estás seguro de eliminar todas las solicitudes?"
        description="Ten en cuenta que esta acción no se puede revertir."
        isLoading={loadingDeletedBulk}
      />

      <UploadFileModal
        isOpen={isOpenUploadModal}
        onClose={handleCloseUploadModal}
        setFileUploaded={setFileUploaded}
        handleUploadFile={handleUploadFile}
        isUploadLoading={isUploadLoading}
        fileUploaded={fileUploaded}
        handleUploadAndSendFile={handleUploadAndSendFile}
      />
      <AssignSupplierModal
        isOpen={isAssignSupplierOpen}
        onClose={handleCloseAssignModal}
        handleRejectedModal={handleRejectedModal}
        handleOpenConversationsModal={handleOpenConversationsModal}
        isRejected={isRejected}
        id={currentTripId}
        conversationsIds={conversationsIds}
        tripData={tripData}
      />
      <CreateTripModal isOpen={openCreateTrip} onClose={handleCreateTrip} onSubmit={handleCreateNewTrip} />
    </Box>
  );
};
export default TripTableTemplate;
