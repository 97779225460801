/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { Box, Icon, Spinner, Text, Progress } from '@chakra-ui/react';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { columns } from './columnsTripTable';

const TableTripRequest = ({
  handleColumnSort,
  SortIcon,
  openChatModal,
  openTripRequestModal,
  flexRender,
  data,
  loading,
  hasSupplierCoordination,
  hasClientCoordination,
  handleApprove,
  handleOpenConversationsModal,
  openForwardMessageModal,
  openAssignSupplierModal,
  setShowModal,
  readStatus,
  disabledButtons,
  handleReadStatusToggle,
  setData,
  columnFilters,
  checkedItems,
  handleCheckboxChange,
  handleRejectedModal,
  elementShow,
  withMaxScreen,
  setWithMaxScreen,
  selectAll,
  handleSelectAll,
  handleAssignModal,
  openAssignSupplierTrafficModal,
  refreshTripRequests,
}) => {
  let columnsTable = columns(
    openChatModal,
    hasClientCoordination,
    handleApprove,
    hasSupplierCoordination,
    handleOpenConversationsModal,
    openForwardMessageModal,
    openAssignSupplierModal,
    setShowModal,
    readStatus,
    disabledButtons,
    handleReadStatusToggle,
    openTripRequestModal,
    checkedItems,
    handleCheckboxChange,
    handleRejectedModal,
    selectAll,
    handleSelectAll,
    handleAssignModal,
    openAssignSupplierTrafficModal,
    refreshTripRequests
  );

  const table = useReactTable({
    data,
    columns: columnsTable,
    state: {
      columnFilters,
    },
    initialState: {
      pageIndex: 0,
      pageSize: elementShow,
      pagination: {
        pageIndex: 0,
        pageSize: elementShow,
      },
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    meta: {
      updateData: (rowIndex, columnId, value) =>
        setData((prev) =>
          prev.map((row, index) =>
            index === rowIndex
              ? {
                  ...prev[rowIndex],
                  [columnId]: value,
                }
              : row
          )
        ),
    },
  });

  useEffect(() => {
    table.setPageSize(elementShow);
    setWithMaxScreen(table?.getTotalSize());
  }, [elementShow, table]);
  const [hovered, setHovered] = React.useState(null);
  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        position: 'relative',
      }}
      maxWidth={withMaxScreen}
    >
      <Box
        className="table notranslate"
        minHeight={'100%'}
        display={{ base: 'none', lg: 'block' }} // breakpoint lg
        maxWidth={withMaxScreen}
        minWidth={'800px'}
        style={{
          borderLeft: '1px solid #e1e1e1', // Lighter border color
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <Box
            key={headerGroup.id}
            width={withMaxScreen - 2}
            position={'sticky'}
            top={5}
            zIndex={2}
            bg="white"
            style={{
              borderRight: '1px solid #e1e1e1', // Lighter border color
            }}
          >
            <Box
              className="tr"
              key={headerGroup.id}
              style={{
                borderTop: '1px solid #e1e1e1',
              }}
            >
              {headerGroup.headers.map((header) => {
                const validateStickyHeader = header.column.id === 'respond' && table.getRowModel().rows.length !== 0;
                return (
                  <Box
                    className="th"
                    width={header.column.columnDef.header === null ? '0px' : header.getSize()}
                    key={`${header.id}-${Date.now()}-${Math.random()}`}
                    style={{
                      display: validateStickyHeader && 'none',
                      textAlign: 'center',
                      background: 'white',
                      textWrap: 'wrap',
                    }}
                  >
                    {header.column.columnDef.header}
                    {header.column.columnDef.accessorKey !== 'status' &&
                      header.column.columnDef.accessorKey !== 'selected' &&
                      header.column.columnDef.accessorKey !== 'folio_number' &&
                      header.column.columnDef.accessorKey !== 'shipment_number' &&
                      header.column.columnDef.header !== null &&
                      header.column.getCanSort() && (
                        <Icon as={SortIcon} mx={3} fontSize={14} onClick={() => handleColumnSort(header.column)} />
                      )}
                    {
                      {
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted()]
                    }

                    {table.getRowModel().rows.length !== 0 && (
                      <Box
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ' '}`}
                      >
                        {' '}
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        ))}
        {loading && (
          <>
            {/* Top loader */}
            <Box
              position="absolute"
              top={0}
              left={0}
              right={0}
              zIndex={3}
            >
              <Progress
                size="xs"
                isIndeterminate
                colorScheme="blue"
                bg="transparent"
              />
            </Box>
            
            {/* Bottom loader */}
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              zIndex={3}
            >
              <Progress
                size="xs"
                isIndeterminate
                colorScheme="blue"
                bg="transparent"
              />
            </Box>
          </>
        )}
        {table.getRowModel().rows.length === 0 ? (
          <Box
            pt={4}
            height={240}
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="99.98%"
            position={'sticky'}
            bg="white"
            style={{
              borderRight: '1px solid #e1e1e1',
              borderBottom: '1px solid #e1e1e1',
            }}
          >
            <Text fontWeight="normal">No hay solicitudes de viaje</Text>
          </Box>
        ) : (
          <Box
            w="99.99%"
            position={'sticky'}
            bg="white"
            style={{
              borderRight: '1px solid #e1e1e1',
            }}
          >
            {table.getRowModel().rows.map((row) => {
              const cells = row.getVisibleCells();
              const lastCell = cells[cells.length - 1];
              const haveBeenSeen = readStatus[row.original.id];
              return (
                <Box
                  className="tr"
                  key={`${row.id}-${Date.now()}-${Math.random()}`}
                  _hover={{
                    borderBottom: '1px solid',
                    borderTop: '1px solid',
                    borderColor: 'gray.400',
                  }}
                  minH={'55px'}
                  bg={haveBeenSeen ? 'blue.50' : 'white'}
                  onMouseEnter={() => setHovered(row.original.id)}
                  onMouseLeave={() => setHovered(null)}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Box
                        w={lastCell.id !== cell.id && cell.column.getSize()}
                        border="none"
                        borderBottom="1px solid #E2E8F0"
                        style={
                          lastCell.id === cell.id
                            ? {
                                position: 'absolute',
                                visibility: hovered === cell.row.original.id ? 'visible' : 'hidden',
                                width: hasSupplierCoordination ? '15%' : '12%',
                                right: 0,
                                backgroundColor: haveBeenSeen ? '#F3F5FF' : 'white', //this is a problem
                                zIndex: 999,
                              }
                            : {}
                        }
                        key={`${row.id}-${Date.now()}-${Math.random()}`}
                        onClick={
                          cell.column.columnDef.isInteractive
                            ? null
                            : cell.column?.columnDef?.isOpenMessage
                              ? () => openChatModal(row.original.id)
                              : cell.column?.columnDef?.isOpenAssigned
                                ? () =>
                                    handleAssignModal({
                                      id: row.original.id,
                                      forwarded_supplier_chats: row.original.forwarded_supplier_chats,
                                      is_rejected: row.original.is_rejected,
                                      origin: row.original?.origin,
                                      destination: row.original.destination,
                                      client_name: row.original.client_name,
                                    })
                                : () => openTripRequestModal(row.original.id, row?.original?.file)
                        }
                      >
                        <Box border="none">{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box>
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TableTripRequest;
